import React from 'react'
import Sidebar from '../Sidebar'
import './style.scss'

import Client from 'shopify-buy'

class ProductTemplateDetails extends React.Component {

  render() {

    const { product } = this.props.pageContext

    async function checkout () {
      // build a client
      const client = Client.buildClient({
        storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN,
        domain: `${process.env.GATSBY_SHOP_NAME}.myshopify.com`,
      })
    
      // create a checkout
      const checkout = await client.checkout.create()
      
      window.open(checkout.webUrl)
    }

    return (
      <div>
        <div className="content">
          <div className="content__inner">
            <div>
              <img src={product.images[0].originalSrc} alt={product.handle} />
              <h2 className="productDetail_title">{product.title}</h2>
              <div className="productDetail_description">{product.description}</div>
              <button onClick={checkout}>Buy for €{product.priceRange.minVariantPrice.amount}</button>
            </div>
          </div>
        </div>
        <Sidebar {...this.props} />
      </div>
    )
  }
}

export default ProductTemplateDetails
